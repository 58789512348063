import React from "react";
import Layout from "../components/layout";
import FormSpree from "../components/FormSpree";
import SEO from "../components/seo";
import contactStyles from "./contact.module.css";

const ContactPage = (props) => {
  return (
    <Layout>
      <SEO title="Contact" />
      <div className={`container container--page ${contactStyles.contact_page}`}>
        <div className={contactStyles.sectionWrapper}>
          <section>
            <h2>Contact KSGA, LLP</h2>
            <FormSpree />

            {/* <form
              className="wpcf7-form"
              method="POST"
              netlify
              name="ksga-contact"
              action="/success"
            >
              <input type="hidden" name="ksga-contact" value="contact"></input>
              <div className="apus-schedule-visit-form">
                <div className="form-schedule-wrap">
                  <p className="form-schedule-input">
                    <span className="wpcf7-form-control-wrap text-242">
                      <input
                        type="text"
                        name="name"
                        size="40"
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required input-text"
                        placeholder="Your Name"
                      />
                    </span>
                  </p>
                  <p className="form-schedule-input">
                    <span className="wpcf7-form-control-wrap email-163">
                      <input
                        type="email"
                        name="email"
                        size="40"
                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email input-text"
                        aria-required="true"
                        aria-invalid="false"
                        placeholder="Your Email"
                      />
                    </span>
                  </p>
                </div>
                <div className="form-schedule-wrap">
                  <p className="form-schedule-input">
                    <span className="wpcf7-form-control-wrap text-242">
                      <input
                        type="text"
                        name="phone"
                        size="40"
                        className="wpcf7-form-control wpcf7-text input-text"
                        aria-invalid="false"
                        placeholder="Phone Number"
                      />
                    </span>
                  </p>
                </div>
                <div className="form-schedule-wrap-end">
                  <p className="form-schedule-input">
                    <span className="wpcf7-form-control-wrap textarea-525">
                      <textarea
                        name="message"
                        cols="40"
                        rows="10"
                        className="wpcf7-form-control wpcf7-textarea input-text"
                        aria-invalid="false"
                        placeholder="Write Your Message"
                      ></textarea>
                    </span>
                  </p>
                </div>
                <div className="form-schedule-submit">
                  {" "}
                  <button
                    type="submit"
                    value="Submit"
                    className="wpcf7-form-control wpcf7-submit btn btn-block"
                  >
                    Send
                  </button>
                </div>
              </div>
              <div className="wpcf7-response-output wpcf7-display-none"></div>
            </form> */}
          </section>
          <section>
            <iframe
              className={contactStyles.actAsDiv}
              src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=KSGA%2C%20LLP%2017918%20Pioneer%20Blvd.%2C%20%23200%2C%20Artesia%2C%20CA%2090701&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=14&amp;iwloc=A&amp;output=embed"
            ></iframe>
          </section>
        </div>
        <div className={contactStyles.sectionWrapper}>
          <section>
            <h3>Telephone &amp; Fax</h3>
            <p>&nbsp; Call (worldwide): (562) 865-2727</p>
            <p>&nbsp; Toll-free (USA): (800) 440-2715</p>
            <p>&nbsp; Fax: (562) 865-2760</p>
            <p>&nbsp;</p>
            <h3>Hours</h3>
            <p>&nbsp; 8:30 a.m. to 5 p.m.</p>
            <p>&nbsp; Monday through Friday</p>
            <p>&nbsp; (Extended hours during tax season)</p>
            <p>&nbsp;</p>
            <h3>For more information</h3>
            <p>&nbsp; Please Call</p>
            <p>&nbsp; (562) 865-2727, Ext. 0 or Ext. 110</p>
            <p>
              &nbsp; Or Email:{" "}
              <a className="nonblock" href="mailto:info@ksgallp.com">
                info@ksgallp.com
              </a>
            </p>
          </section>
          <section>
            <iframe
              className={contactStyles.youtube}
              src="//www.youtube.com/embed/OiV0Ruz82xU?autoplay=0&loop=0&showinfo=0&theme=light&color=red&controls=1&modestbranding=0&start=0&fs=1&iv_load_policy=1&wmode=transparent&rel=1"
            ></iframe>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
